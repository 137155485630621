exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bookkeeping-[service]-js": () => import("./../../../src/pages/bookkeeping/[service].js" /* webpackChunkName: "component---src-pages-bookkeeping-[service]-js" */),
  "component---src-pages-compliance-[service]-js": () => import("./../../../src/pages/compliance/[service].js" /* webpackChunkName: "component---src-pages-compliance-[service]-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-payroll-[service]-js": () => import("./../../../src/pages/payroll/[service].js" /* webpackChunkName: "component---src-pages-payroll-[service]-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-self-assessment-[service]-js": () => import("./../../../src/pages/self-assessment/[service].js" /* webpackChunkName: "component---src-pages-self-assessment-[service]-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

